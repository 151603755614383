/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1023Actions from "../../actions/w18F1023-actions";

import {Column} from 'devextreme-react/data-grid';
import PopupView from "../popup/popup-view";
import {FormGroup, Col} from 'react-bootstrap';
import Config from "../../config";
import TreeList, {HeaderFilter, Selection} from "devextreme-react/tree-list";
import * as w18Actions from "../../actions/w18-actions";
import ButtonApprove from "../common/button/button-approve";
import updateSalesPerson from "../../actions/w18/w18F1016/update-saleperson-async";
import insertHistory from "../../actions/w18/w18F1016/insert-history-async";
import notify from "devextreme/ui/notify";
import {Loading} from "../common/loading/loading";
import EventTracking from "../common/tracking/event";
import {Input} from "reactstrap";


class W18F1010Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ID = '';
        this.dataTree = [];
        this.defaultRowKeys = [];
        this.state = {
            loading: false,
        };
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;

    }


    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadDataTreeList() {
        this.setState({loading: true});
        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, this.timeDeplay);
        const uName = Config.profile.UserID || '';
        const params = {
            "UserID": uName
        };
        this.props.w18f1023Actions.loadTree(params, (error, data) => {
            if (error) {
                this.setState({loading: false});
                Config.popup.show('INFO', `${Config.lang("CRM_Loi_chua_xac_dinh")}`);
            }
            if (data.length > 0) {
                // this.dataTree = data.map(o => {
                //     // console.log("dataTree dataTree",o);
                //     const item = {
                //         GroupSalesID: o.GroupSalesID,
                //         GroupSalesNameU: o.GroupSalesNameU,
                //         GroupSalesParentID: o.GroupSalesParentID,
                //         IsSalesPerson: o.IsSalesPerson,
                //         JobPositionU: o.JobPositionU,
                //         URL: o.URL,
                //     };
                //     return item
                // });
                const defaultExpandedRowKeys = data.filter((row) => {
                    return row.GroupSalesParentID === '0';
                });
                if (defaultExpandedRowKeys.length > 0) {
                    this.defaultRowKeys.push(defaultExpandedRowKeys[0].GroupSalesID);
                }
                this.setState({loading: false});
                return false;
            } else {
                return false;
            }
        });
    }

    componentDidMount() {
        this.loadDataTreeList()
    }

    async saveData() {
        const {data} = this.props;
        const reasonTransfer = document.getElementById("ReasonTransfer").value;
        if (!this.ID) {
            Config.popup.show('INFO', `${Config.lang("CRM_Ban_chua_chon_chu_so_huu")}`);
            return false
        }
        for (let i = 0; i < data.length; i++) {
            const paramUpdate = {
                "CompanyID": data[i].CompanyID,
                "SalesPersonID": this.ID,
            };
            const paramInsert = {
                'TransType': 'TransferSale',
                'CompanyID': data[i].CompanyID,
                'CompanyStatus': data[i].CompanyStatus,
                'ReceiveUserID': this.ID,
                'ReasonTransfer': reasonTransfer,
                'Event': 'ADD',
            };
            const resUpdate = await updateSalesPerson(paramUpdate)
            if (resUpdate.code !== 200) {
                Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_khi_luu")}`);
                return false;
            }
            const resRemove = await insertHistory(paramInsert);
            if (resRemove.code !== 200) {
                Config.popup.show('INFO', `${Config.lang("CRM_Co_loi_khi_luu")}`);
                return false;
            }
            notify(`${Config.lang("CRM_Luu_thanh_cong")}`, "success", 2000);
        }
    }

    render() {
        const {treeData} = this.props;
        return (
            <PopupView
                title={`${Config.lang("CRM_Co_cau_kinh_doanh")}`}
                show={true}
                animation={true}
                onHide={() => this.onHide()}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1023"} label={Config.profile.UserID}/>}

                <div className="page-container" id={'frmW18F1023'}>
                    {this.state.loading && <Loading/>}
                    <FormGroup className="pdt10">
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonApprove name={`${Config.lang("CRM_Dong_y")}`} className={'pull-left'}
                                           onClick={() => this.saveData()}/>
                        </Col>
                    </FormGroup>
                    <FormGroup className={'mgt5'}>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            {treeData && <TreeList
                                showBorders={true}
                                columnAutoWidth={true}
                                wordWrapEnabled={true}
                                keyExpr={'GroupSalesID'}
                                defaultExpandedRowKeys={this.defaultRowKeys}
                                dataSource={treeData}
                                parentIdExpr={'GroupSalesParentID'}
                                // autoExpandAll={true}
                                showRowLines={false}
                                showColumnHeaders={false}
                                height={500}
                                width={'100%'}

                                onRowClick={(e) => {
                                    if (e.data.IsSalesPerson === 1) {
                                        this.ID = e.key;
                                    } else {
                                        this.ID = ''
                                    }
                                }}

                            >
                                <HeaderFilter visible={true}/>
                                <Selection mode={'single'}/>

                                <Column dataField={'GroupSalesNameU'}
                                        cellTemplate={(container, options) => {
                                            const data = options.data
                                            if (data.IsSalesPerson === 1) {
                                                container.innerHTML = `<span class="groupSalesChildW18F1023"><img class="avatarW18F1023 img-circle" src='${data.URL ? data.URL : require('../.././images/icon-user-default.png')}' alt="Smiley face" height="42" width="42"><span>${data.GroupSalesNameU ? data.GroupSalesNameU : ''} ${data.JobPositionU ? `(${data.JobPositionU})` : ''}</span></span>`

                                            } else {
                                                container.innerHTML = `<span class="groupSalesParentW18F1023">${data.GroupSalesNameU}</span>`;
                                            }
                                        }}
                                        caption={'Status'} width={300}/>


                            </TreeList>}
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xl={2} xs={2} lg={2}>
                            <label className={'control-label pdt10'}>{Config.lang("CRM_Ly_do_chuyen")}</label>
                        </Col>
                        <Col md={10} sm={10} xl={10} xs={10} lg={10}>
                            <Input id={'ReasonTransfer'} type={'textarea'} rows={2}/>
                        </Col>
                    </FormGroup>
                </div>
            </PopupView>

        )
    }
}

export default connect(state => ({
        treeData: state.w18f1023.treeData,
    }),
    (dispatch) => ({
        w18f1023Actions: bindActionCreators(w18f1023Actions, dispatch),
        w18Actions: bindActionCreators(w18Actions, dispatch),
    })
)(W18F1010Page);